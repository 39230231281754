var _class, _descriptor;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { action, observable } from 'mobx';
import { getInforUserAction } from '@gtintel/platform-api';
import { COOKIE_NAMES } from '@gtintel/platform-constants';
let AuthStore = (_class = class AuthStore {
  constructor(rootStore) {
    this.rootStore = void 0;
    _initializerDefineProperty(this, "authUsers", _descriptor, this);
    this.rootStore = rootStore;
  }
  async getAuthUsers() {
    const user = Cookies.getJSON(COOKIE_NAMES.INFOR_USER);
    if (!isEmpty(user)) {
      const usersResponse = await getInforUserAction(user?.email, false);
      this.authUsers = usersResponse.results;
      return this.authUsers;
    }
    return [];
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authUsers", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _applyDecoratedDescriptor(_class.prototype, "getAuthUsers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "getAuthUsers"), _class.prototype)), _class);
export { AuthStore as default };