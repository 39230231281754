import Cookies from 'js-cookie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setCookie = (key, value) => {
  if (typeof value === 'string' && value.length > 0 || typeof value === 'object' && value) {
    Cookies.set(key, value);
  } else {
    console.log(key, typeof value, value);
    throw new Error(`Value of cookie: ${key} is ${value}`);
  }
};