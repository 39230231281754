var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { action, observable } from 'mobx';
import { getBatchDetail, getBatchDetails, deleteProjects, matchProjects, rematchProject, getProjectsProposals } from '@gtintel/platform-api';
let AdminBatchRequestDetailStore = (_class = class AdminBatchRequestDetailStore {
  constructor(rootStore) {
    this.rootStore = void 0;
    _initializerDefineProperty(this, "batchRequestDetail", _descriptor, this);
    _initializerDefineProperty(this, "matchPartners", _descriptor2, this);
    _initializerDefineProperty(this, "isLoading", _descriptor3, this);
    _initializerDefineProperty(this, "isLoadingMatchPartners", _descriptor4, this);
    _initializerDefineProperty(this, "isDeleteProjectsSuccess", _descriptor5, this);
    _initializerDefineProperty(this, "isMatchProjectsSuccess", _descriptor6, this);
    _initializerDefineProperty(this, "isRematchProjectsSuccess", _descriptor7, this);
    this.rootStore = rootStore;
  }
  async fetchBatchRequestDetail(id) {
    this.isLoading = true;
    const response = await getBatchDetail(id);
    this.batchRequestDetail = response;
    this.isLoading = false;
  }
  async fetchBatchRequestDetailWithFilter(params) {
    this.isLoading = true;
    const formatParams = {
      ...params,
      prosearch: params.searchContent
    };
    const response = await getBatchDetails(formatParams);
    this.batchRequestDetail = response;
    this.isLoading = false;
  }
  async fetchMatchPartners(projectId) {
    this.isLoadingMatchPartners = true;
    const response = await getProjectsProposals(projectId);
    this.matchPartners = response.result;
    this.isLoadingMatchPartners = false;
  }
  async deleteProjects(projectIds) {
    try {
      const formatData = Array.isArray(projectIds) ? projectIds.map(projectId => ({
        project_id: projectId
      })) : [];
      await deleteProjects(formatData);
      this.isDeleteProjectsSuccess = true;
    } catch (error) {
      this.isDeleteProjectsSuccess = false;
    }
  }
  async matchPartnerForProjects(matchProjectsData) {
    try {
      await matchProjects(matchProjectsData);
      this.isMatchProjectsSuccess = true;
    } catch (error) {
      this.isMatchProjectsSuccess = false;
    }
  }
  async rematchPartnerForProjects(rematchProjectsData) {
    try {
      await rematchProject(rematchProjectsData);
      this.isRematchProjectsSuccess = true;
    } catch (error) {
      this.isRematchProjectsSuccess = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "batchRequestDetail", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "matchPartners", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLoadingMatchPartners", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isDeleteProjectsSuccess", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isMatchProjectsSuccess", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isRematchProjectsSuccess", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "fetchBatchRequestDetail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchBatchRequestDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchBatchRequestDetailWithFilter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchBatchRequestDetailWithFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchMatchPartners", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchMatchPartners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteProjects", [action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "matchPartnerForProjects", [action], Object.getOwnPropertyDescriptor(_class.prototype, "matchPartnerForProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rematchPartnerForProjects", [action], Object.getOwnPropertyDescriptor(_class.prototype, "rematchPartnerForProjects"), _class.prototype)), _class);
export { AdminBatchRequestDetailStore as default };