import axios from 'axios';
import Router from 'next/router';
import Cookies from 'js-cookie';
import { COOKIE_NAMES, DOMAIN, ROUTES } from '@gtintel/platform-constants';
function clearCookies() {
  Cookies.remove(COOKIE_NAMES.AUTH);
  Cookies.remove(COOKIE_NAMES.CLIENT_REFRESH);
  Cookies.remove(COOKIE_NAMES.CLIENT_TOKEN);
  Cookies.remove(COOKIE_NAMES.INFOR_USER);
  Cookies.remove(COOKIE_NAMES.NP_REFRESH);
  Cookies.remove(COOKIE_NAMES.NP_TOKEN);
  Cookies.remove(COOKIE_NAMES.REFRESH_TOKEN);
  Cookies.remove(COOKIE_NAMES.TOKEN);
  Cookies.remove(COOKIE_NAMES.TWILIO_USER_TOKEN);
  Cookies.remove(COOKIE_NAMES.TYPE_VIEW);
}
const instance = axios.create({
  baseURL: DOMAIN,
  params: {},
  withCredentials: true
});
instance.interceptors.response.use(response => response, error => {
  if (error.message?.includes('status code 401') || error.response?.status === 401 || error.response?.status === 403) {
    clearCookies();
    Router.replace(ROUTES.LOGIN_PAGE).catch(() => {});
    return Promise.reject(error);
  }
  return Promise.reject(error);
});
export default instance;