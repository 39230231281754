import { api } from '..';
const initializeTeamFormData = data => {
  const formData = new FormData();
  ['client_billing_contact', 'client_billing_reference', 'cx_lead_id', 'name', 'organization', 'subscription_level', 'supplier_billing_reference'].forEach(attr => {
    if (data[attr]) formData.append(attr, String(data[attr]));
  });
  if (data.user_type) formData.append('type', String(data.user_type));
  data.avatar && formData.append('avatar', data.avatar, data.avatar.name);
  return formData;
};
export const getTeams = data => api.teams.list(data).then(response => Promise.resolve(response.data));
export const getTeamsSimplified = data => api.teams.listSimplified(data).then(response => Promise.resolve(response.data));
export const getTeam = teamId => api.teams.getOne(teamId).then(response => Promise.resolve(response.data));
export const getTeammatesNotOnProject = projectId => api.teams.getTeammatesNotOnProject(projectId).then(response => Promise.resolve(response.data));
export const createNewTeam = async data => {
  const formData = initializeTeamFormData(data);
  return api.teams.create(formData).then(({
    data
  }) => Promise.resolve(data));
};
export const updateTeam = data => {
  const formData = initializeTeamFormData(data);
  return api.teams.update(data.id, formData).then(({
    data
  }) => Promise.resolve(data));
};