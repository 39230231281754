const STATUS_OPTIONS = [{
  label: 'Pending',
  value: '1'
}, {
  label: 'Matched',
  value: '3'
}, {
  label: 'Project Activated',
  value: '4'
}, {
  label: 'Complete',
  value: '5'
}, {
  label: 'Closed',
  value: '6'
}];
export function projectStatusMapping(status) {
  const foundStatus = STATUS_OPTIONS.find(option => status === option?.value);
  if (foundStatus) {
    return foundStatus?.label;
  }
  return status;
}
export function getOriginalFileName(imageUrl) {
  const fileName = imageUrl?.substring(imageUrl?.lastIndexOf('/') + 1)?.split('?')?.[0] ?? '';
  if (fileName.match(/(\.).*(\1)/i)) {
    // have more than one "." in fileName => may have uuid prefix => remove it
    const originalFileName = fileName.replace(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\./i, '');
    return originalFileName;
  }
  return fileName;
}