var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { action, observable } from 'mobx';
import { getListCategoryAction, getListTagsInGroupAction, createUserAction, uploadAvatar } from '@gtintel/platform-api';
const paramsDefault = {
  limit: 999,
  offset: 0,
  search: ''
};
let AdminNewUserStore = (_class = class AdminNewUserStore {
  constructor(rootStore) {
    this.rootStore = void 0;
    _initializerDefineProperty(this, "categoryTagList", _descriptor, this);
    _initializerDefineProperty(this, "currentTagList", _descriptor2, this);
    _initializerDefineProperty(this, "isLoading", _descriptor3, this);
    _initializerDefineProperty(this, "isCreateUserSuccess", _descriptor4, this);
    _initializerDefineProperty(this, "errorMessage", _descriptor5, this);
    this.rootStore = rootStore;
  }
  async fetchCategoryTagList() {
    const response = await getListCategoryAction();
    this.categoryTagList = response.results;
  }
  async fetchTagListInGroup(categoryTagId) {
    const response = await getListTagsInGroupAction(paramsDefault, {
      id: Number(categoryTagId)
    });
    this.currentTagList = response.results;
  }
  async submitCreateNewUser(data) {
    const {
      is_administrator,
      is_client,
      is_network_partner,
      role
    } = data;
    const newUser = {
      ...data,
      is_administrator,
      is_client,
      is_network_partner
    };
    this.isLoading = true;
    if (is_client && role === null) {
      this.errorMessage = 'Account Role cannot be empty for client users';
      this.isLoading = false;
      return false;
    }
    if (!is_administrator && !is_client && !is_network_partner) {
      this.errorMessage = 'Account Type cannot be empty';
      this.isLoading = false;
      return false;
    }
    try {
      const response = await createUserAction(newUser);
      if (newUser.imageFile) {
        await uploadAvatar({
          imageFile: newUser.imageFile,
          email: response.email
        });
      }
      this.isLoading = false;
      this.errorMessage = '';
      this.isCreateUserSuccess = true;
      return true;
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      if (error.data?.message[0]) this.errorMessage = error.data.message[0];
      if (error.data?.email) this.errorMessage = `email: ${error.data.email}`;
      if (error.data?.message.phone) this.errorMessage = error.data.message.phone;
      if (error.data?.message.alter_phone) this.errorMessage = `The alternative phone number entered is not valid`;
      return false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "categoryTagList", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentTagList", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isCreateUserSuccess", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return '';
  }
}), _applyDecoratedDescriptor(_class.prototype, "fetchCategoryTagList", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCategoryTagList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchTagListInGroup", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTagListInGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitCreateNewUser", [action], Object.getOwnPropertyDescriptor(_class.prototype, "submitCreateNewUser"), _class.prototype)), _class);
export { AdminNewUserStore as default };