var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { action, observable } from 'mobx';
import { getProjectPartner as getProjectPartnerAction } from '@gtintel/platform-api';
let PartnerStore = (_class = class PartnerStore {
  constructor(rootStore) {
    this.rootStore = void 0;
    _initializerDefineProperty(this, "selectedPartner", _descriptor, this);
    _initializerDefineProperty(this, "projectPartner", _descriptor2, this);
    this.rootStore = rootStore;
  }
  async setSelectedPartner(selectedPartner) {
    this.selectedPartner = selectedPartner;
  }
  async getProjectPartner(projectId) {
    this.projectPartner = await getProjectPartnerAction(projectId);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedPartner", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "projectPartner", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _applyDecoratedDescriptor(_class.prototype, "setSelectedPartner", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedPartner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getProjectPartner", [action], Object.getOwnPropertyDescriptor(_class.prototype, "getProjectPartner"), _class.prototype)), _class);
export default PartnerStore;