import { api } from '..';
export const getListTagsAction = (data = {}) => {
  const params = {
    limit: data.limit,
    offset: data.offset,
    search: data.search
  };
  return api.tags.listTag(params).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};
export const getListTagsInGroupAction = (data, type_group) => {
  const params = {
    limit: data.limit,
    offset: data.offset,
    tag_group_id: type_group.id
  };
  return api.tags.listTagInGroup(params).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};
export const getTagGroupById = categoryId => api.tags.getTagGroupById(categoryId).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
export const getTagGroups = mode => api.tags.getTagGroups(mode).then(response => Promise.resolve(response.data?.results || [])).catch(e => Promise.reject(e.response));
export const getTagTypeOptions = paginationParams => {
  const params = paginationParams ? `?${paginationParams}` : '';
  return api.tags.getTagTypeOptions(params).then(response => Promise.resolve(response.data)).catch(e => Promise.reject(e.response));
};