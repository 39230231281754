// See https://www.twilio.com/docs/api/errors
export const TWILIO_ERRORS = {
  CONVERSATION_NOT_FOUND: 50350
};

// NB the `author` attribute of a twilio Message is the message's user's twilio
// 'identity'. For GTI admins, the identity is TWILIO_ADMIN_IDENTITY. For
// everyone else, it's the user's PK in our DB.

// What Twilio calls a 'Conversation', we sometimes call a 'channel' or a
// 'chat'. Legacy code still has a lot of variables etc. called 'channel' but
// as we move forward let's be consistent and only write 'conversation' when
// referring to a Twilio `Conversation` class.
// Metadata stored on Twilio Conversations
//
// To make this work with Typescript you may have to do something ugly like:
//
//   (conversation.attributes as ConvAttributes).member
// Wrapper around a Twilio `Message` with extra info needed to display in chat.
// Metadata stored on Twilio Messages
//
// `file_id` and `api_root` are added by the Twilio webhook endpoint if the
// message has an attachment.
/* eslint-disable @typescript-eslint/no-explicit-any */
// HACKERY: antd uses this 'UploadFile' type for files uploaded by the <Upload>
// component - but annoyingly, it doesn't export it. I can't see any way to
// make <Upload> play nicely with typescript except to manually copy the Upload
// file type from antd's source code (lib/upload/interface.d.ts)
/* eslint-enable @typescript-eslint/no-explicit-any */