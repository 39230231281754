import React from "react";
var __jsx = React.createElement;
import { useEffect } from 'react';
var HeapAnalytics = function HeapAnalytics() {
  useEffect(function () {
    var _process, _process$env;
    var HEAP_ANALYTICS_ID = (_process = process) === null || _process === void 0 ? void 0 : (_process$env = _process.env) === null || _process$env === void 0 ? void 0 : _process$env.HEAP_ANALYTICS_ID;
    if (HEAP_ANALYTICS_ID) {
      var _document;
      var script = (_document = document) === null || _document === void 0 ? void 0 : _document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = "window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement(\"script\");r.type=\"text/javascript\",r.async=!0,r.src=\"https://cdn.heapanalytics.com/js/heap-\"+e+\".js\";var a=document.getElementsByTagName(\"script\")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=[\"addEventProperties\",\"addUserProperties\",\"clearEventProperties\",\"identify\",\"resetIdentity\",\"removeEventProperty\",\"setEventProperties\",\"track\",\"unsetEventProperty\"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};\n      heap.load(\"".concat(HEAP_ANALYTICS_ID, "\");");
      document.head.appendChild(script);
    }
  }, []);
  return __jsx(React.Fragment, null);
};
export default HeapAnalytics;