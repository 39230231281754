const KEY = 'admin-project-list-filters';
const filters = {
  save: filters => localStorage.setItem(KEY, JSON.stringify(filters)),
  clear: key => {
    if (key) {
      const o = filters.load();
      if (o) {
        delete o[key];
        filters.save(o);
      }
      return;
    }
    localStorage.removeItem(KEY);
  },
  load: () => {
    const raw = localStorage.getItem(KEY);
    let parsed = null;
    try {
      parsed = JSON.parse(raw);
      console.log(`loaded ${KEY} from localStorage`);
    } catch (e) {
      console.warn(`value for ${KEY} in local storage was not JSON`, e);
      filters.clear();
    }
    return parsed;
  }
};
export default {
  filters
};