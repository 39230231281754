import { useState } from 'react';
export function useAbortableAsyncOp(fn, options = {
  isLoading: false
}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(options.isLoading);
  return {
    error,
    isLoading,
    fn: (...args) => {
      setIsLoading(true);
      const [promise, abort] = fn(...args);
      promise.catch(setError).finally(() => setIsLoading(false));
      return [promise, abort];
    }
  };
}
export function useAsyncOp(fn, options = {
  isLoading: false
}) {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(options.isLoading);
  return {
    error,
    isLoading,
    fn: (...args) => {
      setIsLoading(true);
      const promise = fn(...args);
      promise.catch(setError).finally(() => setIsLoading(false));
      return promise;
    }
  };
}