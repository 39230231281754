import Cookies from 'js-cookie';
import { COOKIE_NAMES } from '@gtintel/platform-constants';
import { setCookie } from '@gtintel/platform-utils';
export function resetUserInfoCookie(data) {
  data.tags = [];
  data.tag_groups = [];
  setCookie(COOKIE_NAMES.INFOR_USER, data);
}
export function clearCookies() {
  Cookies.remove(COOKIE_NAMES.AUTH);
  Cookies.remove(COOKIE_NAMES.CLIENT_REFRESH);
  Cookies.remove(COOKIE_NAMES.CLIENT_TOKEN);
  Cookies.remove(COOKIE_NAMES.INFOR_USER);
  Cookies.remove(COOKIE_NAMES.NP_REFRESH);
  Cookies.remove(COOKIE_NAMES.NP_TOKEN);
  Cookies.remove(COOKIE_NAMES.REFRESH_TOKEN);
  Cookies.remove(COOKIE_NAMES.TOKEN);
  Cookies.remove(COOKIE_NAMES.TWILIO_USER_TOKEN);
  Cookies.remove(COOKIE_NAMES.TYPE_VIEW);
  Cookies.remove('is_okta');
}