import { createContext } from 'react';
import { useLocalStorage } from './useLocalStorage';
const FeatureSwitches = {
  sso_login_page: 'off'
};
export const FeaturesContext = createContext(FeatureSwitches);
export const FeaturesProvider = props => {
  const [sso_login_page] = useLocalStorage('sso_login_page', 'pending', 'off');
  const FeatureSwitches = {
    sso_login_page: sso_login_page
  };
  return <FeaturesContext.Provider value={FeatureSwitches}>
      {props.children}
    </FeaturesContext.Provider>;
};