import { PROJECT_TYPE } from '@gtintel/platform-constants';
export const isProjectInStatus = (status, project) => {
  return String(status) === String(project.status);
};
export const getColumnSortOrder = ({
  column,
  order,
  replace
}) => {
  const field = getQueryKeyByColumn(column);
  const replaceTo = (replace && replace(field)) ?? field;
  if (order === 'ascend') {
    return replaceTo;
  }
  if (order === 'descend') {
    return `-${replaceTo}`;
  }
  return '-updated_date';
};
export const getQueryKeyByColumn = column => {
  switch (column) {
    case 'projectName':
      return 'project_name';
    case 'client':
      return 'client_name';
    case 'partner':
      return 'partner_name';
    case 'status':
      return 'status';
    case 'startDate':
      return 'start_date';
    case 'endDate':
      return 'requested_completion_date';
    case 'projectId':
      return 'id';
    case 'totalRevenue':
      return 'final_price';
    case 'profit':
      return 'partner_price';
    case 'createdDate':
      return 'created_date';
    case 'tag':
      return 'tags';
    case 'teamName':
      return 'team_name';
    case 'cxLead':
      return 'cx_lead';
    default:
      return column;
  }
};
export const getSortOrderByColumn = (column, sort) => {
  const sortColumn = getQueryKeyByColumn(column);
  if (sortColumn === sort) {
    return 'ascend';
  }
  if (`-${sortColumn}` === sort) {
    return 'descend';
  }
  return false;
};
export function resetFilters({
  showSizeChanger
}) {
  const pagination = {
    ...this.pagination,
    project_name: undefined,
    client_name: undefined,
    partner_name: undefined,
    start_date: undefined,
    requested_completion_date: undefined,
    created_date: undefined,
    cx_lead: undefined,
    tags: undefined,
    status: undefined,
    showSizeChanger: showSizeChanger || true,
    sortField: '-updated_date'
  };
  this.setState({
    sortField: '-updated_date',
    pagination
  }, () => {
    this.getDataTable(pagination);
  });
}
export function handleTableChange(pagination, filters, sort) {
  const {
    source
  } = this.state;
  let filtersObjects = {};
  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      filtersObjects = {
        ...filtersObjects,
        [getQueryKeyByColumn(key)]: value ? value.join(',') : undefined
      };
    });
  }
  const newPagination = {
    ...this.state.pagination,
    ...pagination,
    sortField: sort,
    ...filtersObjects
  };
  this.setState({
    pagination: newPagination,
    sortField: sort
  }, () => this.getDataTable({
    ...newPagination,
    source
  }));
}
export function onChangeProjectType(projectType) {
  const pagination = {
    current: 1,
    pageSize: 10,
    total: null,
    searchContent: this.state.searchContent,
    sortField: this.state.sortField,
    showSizeChanger: true,
    source: ''
  };
  if (projectType === PROJECT_TYPE.BATCH) {
    pagination.source = PROJECT_TYPE.BATCH;
  }
  if (projectType === PROJECT_TYPE.STANDARD) {
    pagination.source = PROJECT_TYPE.STANDARD;
  }
  if (projectType === PROJECT_TYPE.DRAFT) {
    pagination.source = PROJECT_TYPE.DRAFT;
  }
  this.setState({
    source: pagination.source
  });
  this.getDataTable(pagination);
}