export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const CLIENT_TOKEN = 'client_token';
export const CLIENT_REFRESH = 'client_refresh';
export const NP_TOKEN = 'partner_token';
export const NP_REFRESH = 'partner_refresh';
export const TYPE_VIEW = 'type_view';
export const INFOR_USER = 'infor_user';
export const AUTH = 'auth';
export const TWILIO_USER_TOKEN = 'tw';
export const ROLE_PARTNER = 'Partner Mode';