// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';

const SENTRY_DSN = process.env.SENTRY_DSN;

Sentry.init({
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['*'],
    }),
  ],
  environment: process.env.EXECUTION_ENV,
  send_default_pii: true,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

LogRocket.getSessionURL(sessionURL => {
  Sentry.configureScope(scope => {
    scope.setExtra('sessionURL', sessionURL);
  });
});
