import { api } from '..';
export const getTwilioToken = () => {
  return api.twilio_chat.token().then(response => {
    return Promise.resolve(response.data);
  }).catch(e => {
    return Promise.reject(e.response);
  });
};
export const addMemberToConversation = params => {
  return api.twilio_chat.inviteUser(params).then(response => {
    return Promise.resolve(response.data);
  }).catch(e => {
    return Promise.reject(e.response);
  });
};
export const addAdminToChat = data => {
  return api.twilio_chat.addAdminToChat(data).then(response => {
    return Promise.resolve(response.data);
  }).catch(e => {
    return Promise.reject(e.response);
  });
};
export const removeAdminFromChat = data => {
  return api.twilio_chat.removeAdminFromChat(data).then(response => {
    return Promise.resolve(response.data);
  }).catch(e => {
    return Promise.reject(e.response);
  });
};
export const deleteDataChatChannelAction = data => {
  const param = {
    channel: data
  };
  return api.twilio_chat.deleteDataChatChannel(param).then(response => {
    return Promise.resolve(response.data);
  }).catch(e => {
    return Promise.reject(e.response);
  });
};
export const createExtraProjectChannelAction = data => {
  return api.twilio_chat.createExtraProjectChannel(data).then(response => {
    return Promise.resolve(response.data);
  }).catch(e => {
    return Promise.reject(e.response);
  });
};
export const joinChannel = data => {
  return api.twilio_chat.joinChannel(data).then(response => {
    return Promise.resolve(response.data);
  }).catch(e => {
    return Promise.reject(e.response);
  });
};