import AdminBatchRequestDetailStore from './admin/adminBatchRequestDetailStore';
import AdminMessageStore from './admin/adminMessageStore';
import AdminNewUserStore from './admin/adminNewUserStore';
import AdminProjectDetailStore from './admin/adminProjectDetailStore';
import AuthStore from './authStore';
import PartnerStore from './partnerStore';
import TagGroupStore from './tagGroupStore';
export default class RootStore {
  // common

  // admin

  constructor() {
    this.authStore = void 0;
    this.partnerStore = void 0;
    this.tagGroupStore = void 0;
    this.adminMessageStore = void 0;
    this.adminProjectDetailStore = void 0;
    this.adminNewUserStore = void 0;
    this.adminBatchRequestDetailStore = void 0;
    // common
    this.authStore = new AuthStore(this);
    this.partnerStore = new PartnerStore(this);
    this.tagGroupStore = new TagGroupStore(this);

    // admin
    this.adminMessageStore = new AdminMessageStore(this);
    this.adminProjectDetailStore = new AdminProjectDetailStore(this);
    this.adminNewUserStore = new AdminNewUserStore(this);
    this.adminBatchRequestDetailStore = new AdminBatchRequestDetailStore(this);
  }
}