var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import { action, observable } from 'mobx';
import { listUsers } from '@gtintel/platform-api';
const defaultFetchUserListParams = {
  limit: 10,
  offset: 0,
  search: '',
  tags: ''
};
let AdminMessageStore = (_class = class AdminMessageStore {
  constructor(rootStore) {
    this.rootStore = void 0;
    _initializerDefineProperty(this, "isLoading", _descriptor, this);
    _initializerDefineProperty(this, "hasMore", _descriptor2, this);
    _initializerDefineProperty(this, "currentUserListParams", _descriptor3, this);
    _initializerDefineProperty(this, "userList", _descriptor4, this);
    this.rootStore = rootStore;
  }
  async fetchMoreUsers(pageSize = 10) {
    this.isLoading = true;
    this.currentUserListParams.offset += pageSize;
    const response = await listUsers(this.currentUserListParams);
    // this.userList = this.userList.concat(response.results);
    this.userList = [...this.userList, ...response.results];
    if (!response?.next) {
      this.hasMore = false;
    }
    this.isLoading = false;
  }
  async refetchUsers(isNew = false) {
    this.isLoading = true;
    if (isNew) {
      this.currentUserListParams = {
        ...defaultFetchUserListParams
      };
    }
    const response = await listUsers(this.currentUserListParams);
    this.userList = response.results;
    if (!response?.next) {
      this.hasMore = false;
    }
    this.isLoading = false;
  }
  async changeSearchText(keyword) {
    this.currentUserListParams = {
      ...defaultFetchUserListParams,
      search: keyword
    };
    this.refetchUsers();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isLoading", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasMore", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUserListParams", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {
      ...defaultFetchUserListParams
    };
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userList", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _applyDecoratedDescriptor(_class.prototype, "fetchMoreUsers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "fetchMoreUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refetchUsers", [action], Object.getOwnPropertyDescriptor(_class.prototype, "refetchUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSearchText", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSearchText"), _class.prototype)), _class);
export { AdminMessageStore as default };