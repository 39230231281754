export function getMargin(partnerPrice, clientPrice) {
  if (clientPrice === 0) {
    return 0;
  }
  const profit = getProfit(partnerPrice, clientPrice);
  const margin = profit / clientPrice;
  return margin;
}
export function getProfit(partnerPrice, clientPrice) {
  return clientPrice - partnerPrice;
}
export function getMarginTagColor(margin) {
  if (margin < 0.4) {
    return 'red';
  }
  if (margin < 0.6) {
    return 'orange';
  }
  return 'green';
}