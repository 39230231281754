import React, { createContext, useContext, useState } from 'react';
export const ChatContext = createContext({});
export function ChatProvider({
  children
}) {
  const [chatDetails, setChatDetails] = useState([]);
  const changeChatStatus = (state, channelSid) => {
    const chatInfo = chatDetails.map(chatInfo => {
      if (chatInfo.channelSid === channelSid) {
        chatInfo.isChatOpen = state;
      }
      return chatInfo;
    });
    setChatDetails(chatInfo);
  };
  const createChatDetailRegister = async channelSid => {
    const existsChannelInfo = chatDetails.find(chatInfo => chatInfo.channelSid === channelSid);
    if (!existsChannelInfo) {
      const chatInfo = {
        channelSid,
        isChatOpen: true
      };
      return setChatDetails(prevstate => [...prevstate, chatInfo]);
    }
    changeChatStatus(true, channelSid);
  };
  const verifyIfChatIsOpen = channelSid => {
    const chatFounded = chatDetails.find(item => item.channelSid === channelSid);
    return chatFounded ? chatFounded?.isChatOpen : false;
  };
  return <ChatContext.Provider value={{
    verifyIfChatIsOpen,
    changeChatStatus,
    createChatDetailRegister
  }}>
      {children}
    </ChatContext.Provider>;
}
export function UseChatContext() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
}